<template>
    <!--
    Страница голосования
    -->
    <el-container direction="vertical">
        <el-card class="vote-card">
            <!--            Заголовок-->
            <el-row slot="header" align="middle" justify="space-between" type="flex" class="el-row__header">
                <el-col :span="12">
                    <span style="font-weight: 600;">{{ cardTitle }}</span>
                </el-col>
                <el-col :span="12" style="text-align: right">
                    <el-link type="primary" :underline="false" style="font-size: 16px" @click="onCancel">
                        Закрыть
                        <i class="el-icon--right el-icon-error close-icon"></i>
                    </el-link>
                </el-col>
            </el-row>

            <!--            Адрес-->
            <el-row type="flex" align="middle">
                <dom-label prefix-text="Адрес проведения:" prefix-icon="fas el-icon-fa-map-marker-alt">{{ voteData.property.address }}</dom-label>
            </el-row>

            <!--            Дата проведения-->
            <el-row type="flex" align="middle">
                <dom-label prefix-text="Дата и время проведения:" prefix-icon="el-icon-date">
                    {{ voteData.start_dttm | ru_date_at }} - {{ voteData.end_dttm | ru_date_at }}
                </dom-label>
            </el-row>

            <!--            Статус-->
            <el-row :class="`${status} vote-status-border-${status}`" type="flex" align="middle" style="padding-left: 5px">
                <el-col style="width: 33px">
                    <i :class="`vote-status-icon-${status} vote-status-color-${status} `"></i>
                </el-col>
                <el-col style="padding-left: 10px">
                    <span style="color: initial">Статус: </span>
                    <span class="title">{{ voteData.voting_status.name }}</span>
                </el-col>
                <el-col class="counter" v-if="statusIs(status,'admin_approved', 'approved')">
                    <vue-countdown :time="counterTime" v-slot="{ days, hours, minutes }" style="word-break: break-all;">
                        <span style="color: initial">До начала голосования:</span>
                        <span :class="status + '_color'" class="counter__inner">
                            <span class="counter__digit">{{ days }}</span>
                            <span class="counter__letters">дней</span>
                            <span style="margin-left: 8px">:</span>
                            <span class="counter__digit"> {{ hours }}</span>
                            <span class="counter__letters">часов</span>
                            <span style="margin-left: 8px">:</span>
                            <span class="counter__digit"> {{ minutes }}</span>
                            <span class="counter__letters">минут</span>
                    </span>
                    </vue-countdown>
                </el-col>
            </el-row>

            <!--            Инициатор-->
            <el-row type="flex" align="start" style="padding-top: 10px; padding-bottom: 10px">
                <dom-label prefix-text="Инициатор:" prefix-icon="fas el-icon-fa-user">{{ voteData.initiator.name }}</dom-label>
            </el-row>


            <!--            Администратор -->
            <el-row type="flex" align="start" style="padding-top: 10px; padding-bottom: 10px; flex-direction: column" v-if="voteData.admin">
                <dom-label prefix-text="Администратор:" prefix-icon="fas el-icon-fa-user">
                    {{ voteData.admin | voting_admin }}
                </dom-label>

                <el-col :span="24" style="padding-left: 10px;">
                    <div style="padding: 5px; margin-top: 10px; display: flex; flex-direction: column">
                        <el-button @click="isInitiatorAddInfoActive = !isInitiatorAddInfoActive" class="admin-button" size="mini">
                            <span v-if="!isInitiatorAddInfoActive"><i class="fas el-icon-fa-eye"/>  Посмотреть данные</span>
                            <span v-else><i class="fas el-icon-fa-eye-slash"/>  Скрыть данные</span>
                        </el-button>
                        <template v-if="isInitiatorAddInfoActive">
                            <div style="font-size: 14px; padding: 10px; font-weight: 600">
                                <dom-label prefix-icon="fas el-icon-fa-address-card">{{ voteData.admin.passportNo }}</dom-label>
                                <dom-label prefix-icon="fas el-icon-fa-map-marker-alt" class="mt-5">{{ voteData.admin.address }}</dom-label>
                                <dom-label prefix-icon="fas el-icon-fa-phone-square-alt" class="mt-5">{{ voteData.admin.phoneNumber }}</dom-label>
                            </div>

                        </template>
                    </div>
                </el-col>
            </el-row>

            <!--            Тема-->
            <el-row type="flex" align="middle">
                <dom-label prefix-text="Контактные телефоны админа ОСС:" prefix-icon="fas el-icon-fa-phone-square-alt">{{ voteData.property.adminPhoneNumber }}</dom-label>
            </el-row>

            <!--            Тема-->
            <el-row type="flex" align="middle">
                <dom-label prefix-text="Тема:" prefix-icon="el-icon-warning">{{ voteData.title }}</dom-label>
            </el-row>

            <!--            Собственные голоса-->
            <el-row type="flex" align="middle">
                <dom-label prefix-text="Вы обладаете" prefix-icon="fas el-icon-fa-th-list">{{ ownVotes | float2_0 }}<span style="font-weight: normal"> голосами</span></dom-label>
            </el-row>

            <!--            Кворум -->
            <el-row align="middle" style="display: flex; flex-wrap: wrap" v-if="statusIs(status, 'finished', 'completed') && !$is_admin()">
                <dom-vote-chart-quorum
                    style="width: 100%; max-width: 510px; padding-left: 0;"
                    title="Кворум"
                    :question="questions[0]"
                    :chart-colors=" ['#1d9ef9', '#AEB2BB']"
                />
                <dom-question-stat
                    style="width: 100%; padding-left: 5px; padding-bottom: 10px; padding-right: 15px"
                    :disable-left-block="true"
                    v-if="questions[0]"
                    :question="questions[0]"
                />
            </el-row>

            <!--            Вопросы-->
            <el-row align="middle" v-for="(question, idx) in questions" :key="idx" class="question">

                <!--                Заголовок вопроса-->
                <el-row :gutter="14" type="flex">
                    <el-col :span="2" class="question__index">
                        {{ idx + 1 }}
                    </el-col>
                    <el-col :span="24">
                        <el-row type="flex" class="question__title" align="middle">
                            <i class="el-icon-question question__title_icon"></i>
                            <div class="question__header">{{ question.title }}</div>
                        </el-row>
                        <!--                        Решение-->
                        <el-row type="flex" class="question__title" align="middle" v-if="voteDecisions && voteDecisions[question.questionId] && statusIs(status,'finished', 'completed')">
                            <i class="fas el-icon-fa-check-circle question__title_icon_finished" v-if="voteDecisions[question.questionId].decisionIsMade"></i>
                            <i class="fas el-icon-fa-times-circle question__title_icon_completed" v-else></i>
                            <div class="question__header">Решение:
                                <span style="font-weight: bold" v-if="voteDecisions[question.questionId].decisionIsMade">Принято</span>
                                <span style="font-weight: bold" v-else>Отклонено</span>
                            </div>
                        </el-row>
                        <!--                        Файлы-->
                        <el-row class="question__files" v-for="file in question.files">
                            <dom-file-download class="mr-10"
                                               :title="file.fileName"
                                               link-type="qxfile"
                                               :link-id="file.qxfId"
                                               :file-name="file.fileName"
                                               prefix-icon="fas el-icon-fa-file-alt"/>
                        </el-row>
                    </el-col>
                </el-row>


                <!--                Блок результатов голосования-->
                <el-radio-group v-model="question.answer" class="vote-radio" v-if="statusIs(status, 'finished', 'completed', 'active') && isUserOwner">
                    <template v-if="isUserOwner">
                        <el-radio label="agree" :disabled="statusIs(status, 'completed', 'finished') || $is_admin()">ЗА</el-radio>
                        <el-radio label="disagree" :disabled="statusIs(status, 'completed', 'finished') || $is_admin()">ПРОТИВ</el-radio>
                        <el-radio label="abstain" :disabled="statusIs(status, 'completed', 'finished') || $is_admin()" style="margin-top: 5px">ВОЗДЕРЖАЛСЯ</el-radio>
                    </template>
                </el-radio-group>

                <dom-vote-chart
                    style="padding-left: 0;"
                    title="Решение"
                    :question="question"
                    :chart-colors=" ['#07C98C', '#E93330', '#EECB00', '#AEB2BB']"
                    v-if="statusIs(status, 'finished', 'completed') && !$is_admin()"
                />

                <!--                результаты голосования-->
                <div style="padding: 5px; display: flex; flex-direction: column" v-if="statusIs(status, 'finished', 'completed') && !$is_admin()">
                    <dom-question-stat
                        :question="question"
                    />

                    <el-button @click="question.show_stat = !question.show_stat" class="admin-button" style="width: 300px; margin-top: 10px;" size="mini">
                        <span v-if="!question.show_stat"><i class="fas el-icon-fa-plus-circle"/>  Посмотреть результаты голосования</span>
                        <span v-else><i class="fas el-icon-fa-minus-circle"/>  Скрыть результаты голосования</span>
                    </el-button>

                    <dom-question-stat-table
                        :question="question"
                        :isShow="question.show_stat && voteStatistics[question.questionId] !== null"
                    />
                </div>

                <!--                Алгоритм принятия решения-->
                <el-row v-if="($is_admin() || isAdminOss) && statusIs(status,'init', 'admin_approved')" class="dropdown" align="middle" style="padding-left: 30px; margin: 0" type="flex">
                    <dom-selectable-label :value="question.algorithmCd"
                                          :items="algorithms || []"
                                          class="mt-25 mb-15"
                                          prefix-icon="fas el-icon-fa-chart-bar"
                                          prefix-text="Алгоритм принятия решения: "
                                          value-key="cd"
                                          text-key="nm"
                                          use-key
                                          @input="onSaveAlgorithm($event, question)"/>
                </el-row>
            </el-row>

            <!--            Документы-->
            <el-row style="padding: 0 !important;">
                <el-button @click="show_documents = !show_documents" class="show-documents-button" size="mini">
                    <span class="text">Загруженных документов {{ documents.length }}</span>
                    <span v-if="show_documents" class="title">
                        Скрыть список
                        <i class="fas el-icon-fa-eye-slash"/>
                    </span>
                    <span v-else class="title">
                        Показать список
                        <i class="fas el-icon-fa-eye"/>
                    </span>
                </el-button>

                <vote-documents v-if="show_documents"/>
            </el-row>

            <!--            Файлы-->
            <el-row class="p-15" type="horizontal" v-if="(canGetMessage)  && statusIs(status,'approved','active','completed','finished','canceled')">
                <dom-file-download class="mr-10"
                                   :file-id="voteData.message.fileId"
                                   title="Сообщение о проведении ОСС" file-name="Сообщение о проведении ОСС.pdf" prefix-icon="fas el-icon-fa-file-alt"/>
            </el-row>
            <el-row class="p-15" type="horizontal" v-if="voteData.photo_act">
                <dom-file-download :file-id="voteData.photo_act.fileId"
                                   title="Акт фотофиксации.pdf" file-name="Акт фотофиксации.pdf" prefix-icon="fas el-icon-fa-file-alt"/>
            </el-row>
            <el-row class="p-15" type="horizontal" v-if="(canShowProtocol || canShowProtocolAttachements)  && statusIs(status,'completed')">
                <dom-file-download v-if="canShowProtocol"
                                   class="mr-10"
                                   :file-id="voteData.protocol.fileId"
                                   title="Протокол.pdf" file-name="Протокол.pdf" prefix-icon="fas el-icon-fa-file-alt"/>

                <dom-file-download v-if="canShowProtocolAttachements && isAdminOss"
                                   class="mr-10"
                                   :file-id="voteData.attachment.fileId"
                                   title="Приложение.pdf" file-name="Приложение.pdf" prefix-icon="fas el-icon-fa-file-alt"/>
            </el-row>


            <template v-if="isAdminOss && (statusIs(status,'approved', 'active'))">
                <dom-upload style="margin: 30px 0" @input="onAddPhotoAct">Загрузить акт фотофиксации</dom-upload>
                <code-confirmation-dialog v-model="photoActConfirmationDialog" :request-code="onGetVoteCode" :apply-code="onAddPhotoActConfirmed"/>
            </template>


            <!--            Кнопки подтверждения/отказа -->
            <template v-if="$is_admin()">

                <span><template v-if="statusIs(status,'init') && !prop_vote_has_admin">
                    <dom-button-accept label="Подтвердить Администратора ОСС" @click="onAdminApprove"/>
                    <voting-status-confirmation-dialog v-model="confirmAdminConfirmationDialog" status='admin_approved' @done="onStatusChanged"/>

                    <dom-button-decline label="Отказать в подтверждении Администратора ОСС" @click="declineAdminConfirmationDialog = true"/>
                    <voting-status-confirmation-dialog v-model="declineAdminConfirmationDialog" status='declined' @done="onStatusChanged"/>
                </template></span>

                <span><template v-if="statusIs(status,'admin_approved', 'init', 'approved', 'active')">
                    <dom-button-decline label="Отменить голосование" @click="isCancelVoteDialogVisible = true"/>
                    <vote-cancel-dialog v-model="isCancelVoteDialogVisible" :id="id" @done="onStatusChanged"/>
                </template></span>
            </template>

            <template v-if="statusIs(status,'active') ">
                <dom-button-accept label="Подтвердить свой голос" :disabled="!allVotesMade" @click="voteConfirmationDialog=true" style="margin-left: 10px;"/>
                <code-confirmation-dialog v-model="voteConfirmationDialog" :request-code="onGetVoteCode" :apply-code="onVoteCode"/>
            </template>

            <template v-if="isAdminOss">
                <span><template v-if="statusIs(status,'admin_approved', 'init')">
                    <dom-button-accept label="Подтвердить голосование" @click="onApprove"/>
                    <voting-status-confirmation-dialog v-model="confirmVotingDialog" status='approved' @done="onStatusChanged"/>

                    <dom-button-decline label="Отказать в проведении голосования" @click="isDenyVoteDialogVisible = true"/>
                    <vote-deny-dialog v-model="isDenyVoteDialogVisible" :id="id" @done="onStatusChanged"/>
                </template></span>


                <span><template v-if="statusIs(status,'active')">
                    <dom-button-accept label="Выгрузить бланк бюллетеня" @click="downloadPaperBulletinDialog=true" style="margin-left: 10px;"/>
                    <!--            Диалог выгрузки бумажного бюллетеня-->
                    <create-paper-bulletin-dialog v-if="downloadPaperBulletinDialog" v-model="downloadPaperBulletinDialog"/>

                    <dom-button-accept label="Обработать бумажный бюллетень" @click="isAddPaperBulletinDialogVisible=true"/>
                    <!--            Диалог обработки бумажного бюллетеня-->
                    <add-paper-bulletin-dialog v-if="isAddPaperBulletinDialogVisible" v-model="isAddPaperBulletinDialogVisible"/>
                </template></span>

                <span><template v-if="statusIs(status,'finished')">
                    <dom-button-accept label="Сформировать протокол" @click="createProtocolDialog=true"/>
                    <code-confirmation-dialog v-model="createProtocolDialog" :request-code="onGetVoteCode" :apply-code="onProtocolCode"/>
                </template></span>

                <span><template v-if="statusIs(status,'active','approved')">
                    <dom-button-accept label="Сформировать все бумажные решения" @click="createPaperDecisions"/>
                </template></span>


                <span><template v-if="statusIs(status,'active')">
                    <dom-button-accept label="Напомнить о собрании" @click="votingRemind"/>
                </template></span>

            </template>

            <span>
                <template v-if="statusIs(status,'active','approved','finished','completed') && ($is_admin() || isAdminOss)">
                        <dom-button-accept label="Сформировать реестр собственников" @click="createOwnersRegistry"/>
                </template>
            </span>
            <span>
                <template v-if="statusIs(status,'active','approved','finished','completed') && ($is_admin() || isAdminOss)">
                        <dom-button-accept label="Сформировать реестр помещений без права собственности" @click="createNoOwnerReestr"/>
                </template>
            </span>


            <template v-if="$is_admin()">
                <br>
                <dom-button-accept label="Перейти к началу голосования" @click="votingStart"/>
                <dom-button-accept style="margin-left: 0;" label="Перейти к завершению голосования" @click="votingEnd"/>
            </template>

            <!--            <el-row type="flex" align="middle" class="mt-35">-->
            <!--                <dom-button @click="status='init'">init</dom-button>-->
            <!--                <dom-button @click="status='declined'">declined</dom-button>-->
            <!--                <dom-button @click="status='admin_approved'">admin_approved</dom-button>-->
            <!--                <dom-button @click="status='approved'">approved</dom-button>-->
            <!--                <dom-button @click="status='finished'">finished</dom-button>-->
            <!--                <dom-button @click="status='completed'">completed</dom-button>-->
            <!--                <dom-button @click="status='canceled'">canceled</dom-button>-->
            <!--                <dom-button @click="status='active'">active</dom-button>-->
            <!--            </el-row>-->

        </el-card>

    </el-container>

</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';
import AddPaperBulletinDialog from "@/views/dialogs/AddPaperBulletinDialog";
import VoteDenyDialog from "@/views/dialogs/VoteDenyDialog";
import VoteCancelDialog from "@/views/dialogs/VoteCancelDialog";
import DomButton from "@/views/elements/DomButton";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomButtonDecline from "@/views/elements/DomButtonDecline";
import VotingStatusConfirmationDialog from "@/views/dialogs/VotingStatusConfirmationDialog";
import {mapFields} from "vuex-map-fields";
import api from "@/api";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import DomQuestionStat from "@/views/components/DomQuestionStat";
import DomLabel from "@/views/elements/DomLabel";
import DomEditableLabel from "@/views/editor/DomEditableLabel";
import DomSelectableLabel from "@/views/editor/DomSelectableLabel";
import VoteDocuments from "@/views/components/VoteDocuments";
import DomFileDownload from "@/views/elements/DomFileDownload";
import CreatePaperBulletinDialog from "@/views/dialogs/CreatePaperBulletinDialog";
import DomUpload from "@/views/elements/DomUpload";
import DomVoteChart from "@/views/elements/DomVoteChart";
import DomVoteChartQuorum from "@/views/elements/DomVoteChartQuorum";
import DomQuestionStatTable from "@/views/elements/DomQuestionStatTable";
import _ from 'lodash';

export default {

    props: {
        id: {
            required: true,
        },
    },
    components: {
        DomButton,
        DomQuestionStatTable,
        DomVoteChart,
        DomVoteChartQuorum,
        DomUpload,
        CreatePaperBulletinDialog,
        DomFileDownload,
        VoteDocuments,
        DomSelectableLabel,
        DomEditableLabel,
        DomLabel,
        DomQuestionStat,
        CodeConfirmationDialog,
        VotingStatusConfirmationDialog,
        DomButtonDecline,
        DomButtonAccept,
        VoteDenyDialog,
        VoteCancelDialog,
        AddPaperBulletinDialog,
        VueCountdown
    },

    data: () => ({
        isAddPaperBulletinDialogVisible: false,
        isDenyVoteDialogVisible: false,
        isCancelVoteDialogVisible: false,
        isInitiatorAddInfoActive: false,

        confirmAdminConfirmationDialog: false,
        declineAdminConfirmationDialog: false,
        confirmVotingDialog: false,
        voteConfirmationDialog: false,
        createProtocolDialog: false,
        downloadPaperBulletinDialog: false,
        photoActConfirmationDialog: false,

        photoAct: null,

        show_documents: false,

        updateTimeoutId: null,
        // status: null,
    }),

    created() {
        this.clear()
        this.update()
    },

    destroyed() {
        if (this.updateTimeoutId)
            clearTimeout(this.updateTimeoutId);
    },

    asyncComputed: {
        algorithms() {
            return this.getAlgorithms();
        },

        ownVotes() {
            return this.$api.users.getMyVotes(this.id)
        }
    },

    computed: {
        ...mapFields("user", ["me"]),
        ...mapGetters("voting", {voting_id: 'voting_id', voteData: "voting"}),
        ...mapFields("voting", {voteStatistics: "statistics", voteDecisions: "decisions", documents: 'documents'}),

        isUserOwner() {
            return this.voteData.property.owners && this.me.userId && this.voteData.property.owners.some(el => el.userId === this.me.userId)
        },

        prop_vote_has_admin() {
            return this.voteData.property.admin
        },

        status() {
            return (this.voteData && this.voteData.voting_status) ? this.voteData.voting_status.cd : "";
        },

        isAdminOss() {
            return this.voteData.admin && this.me && (this.voteData.admin.userId === this.me.userId)
        },

        canShowProtocol() {
            return this.voteData.protocol && this.voteData.protocol.fileId
        },

        canGetMessage() {
            return this.voteData.message && this.voteData.message.fileId
        },

        canShowProtocolAttachements() {
            return this.voteData.attachment && this.voteData.attachment.fileId
        },

        counterTime() {
            let number = moment(this.voteData.start_dttm) - moment();
            return number > 0 ? number : 0
        },

        questions() {
            return this.voteData ? _.orderBy(this.voteData.questions || [], 'ordr') : []
        },

        allVotesMade() {
            return _.filter(this.questions, 'answer').length === this.questions.length
        },

        allQuestionsHasAlgorythm() {
            return _.filter(this.questions, 'algorithmCd').length === this.questions.length
        },

        cardTitle() {
            return this.voteData.title || 'Голосование'
        },

    },

    methods: {
        ...mapActions("voting", [
            "clear",
            "load_by_id",
            "load_docs",
            "get_statistics",
            "get_decisions",
            "save_voting_field",
            "save_question_field"
        ]),
        ...mapActions("questions", ["getAlgorithms"]),
        ...mapActions("reports", {
            report_createNoOwnerReestr: "createNoOwnerReestr",
            report_createOwnersRegistry: "createOwnersRegistry",
            report_createPaperDecisions: "createPaperDecisions"
        }),

        statusIs(status, ...statuses) {
            return statuses.includes(status)
        },

        update() {
            this.load_by_id(this.id)
            this.get_statistics(this.id)
            this.get_decisions(this.id)
            this.load_docs(this.id)
        },

        onGetVoteCode() {
            return api.voting.getVoteCode(this.voting_id)
        },

        onVoteCode(code) {
            const votes = _.map(this.voteData.questions, d => ({
                question_id: d.questionId,
                vote: d.answer,
            }));

            return api.voting.setVote(this.voting_id, votes, code)
                .then(() => {
                    this.voteConfirmationDialog = false;
                    this.get_statistics(this.id)
                    this.$message.success("Спасибо, Ваш голос учтен!")
                })

        },

        onApprove() {
            // if (!this.checkSecretary()) return
            if (!this.checkAlgorithms()) return
            this.confirmVotingDialog = true
        },

        onAdminApprove() {
            if (!this.checkAlgorithms()) return
            this.confirmAdminConfirmationDialog = true
        },

        onStatusChanged() {
            this.confirmAdminConfirmationDialog = false;
            this.declineAdminConfirmationDialog = false;
            this.confirmVotingDialog = false;
            this.update()
        },

        onCancel() {
            this.$router.push('/');
        },

        checkAlgorithms() {
            if (!this.allQuestionsHasAlgorythm) {
                this.$message.error("У всех вопросов должен быть указан алгоритм принятия решения")
                return false;
            }
            return true;
        },

        onSaveSecretary(value) {
            const id = this.voting_id
            const field = 'secretary_nm'
            this.save_voting_field({id, field, value})
                .then(() => this.update())
        },

        onSaveAlgorithm(value, question) {
            const voting_id = this.voting_id
            const question_id = question.questionId
            const field = 'algorithmCd'
            this.save_question_field({voting_id, question_id, field, value})
                .then(() => this.update())
        },

        onProtocolCode(code) {
            return api.voting.makeProtocol(this.voting_id, code)
                .then(() => this.update())
        },

        onAddPhotoAct({file}) {
            this.photoAct = file;
            this.photoActConfirmationDialog = true;
        },

        onAddPhotoActConfirmed(code) {
            return api.voting.uploadPhotoAct(this.voting_id, code, this.photoAct.raw)
                .then(() => this.$message.success("Файл успешно загружен"))
                .then(() => this.update())
        },

        votingStart() {
            this.$api.debug.votingStart(this.voting_id).then(() => {
                if (this.updateTimeoutId)
                    clearTimeout(this.updateTimeoutId);

                this.updateTimeoutId = setTimeout(() => {
                    this.clear();
                    this.update();
                }, 5.5 * 60 * 1000)
            });
        },

        votingEnd() {
            this.$api.debug.votingEnd(this.voting_id).then(() => {
                if (this.updateTimeoutId)
                    clearTimeout(this.updateTimeoutId);

                this.updateTimeoutId = setTimeout(() => {
                    this.clear();
                    this.update();
                }, 5.5 * 60 * 1000)
            });
        },

        votingRemind() {
            this.$api.voting.votingRemind(this.voting_id).then(() => {
                this.$message.success("Запрос выполнен.");
            }).catch(err => {
                console.log(err);
                this.$message.error('Ошибка запроса');
            });
        },

        createOwnersRegistry() {
            this.report_createOwnersRegistry({voting_id: this.voting_id})
        },

        createNoOwnerReestr() {
            this.report_createNoOwnerReestr({voting_id: this.voting_id})
        },

        createPaperDecisions() {
            this.report_createPaperDecisions({voting_id: this.voting_id})
        },

    },

    filters: {
        voting_admin(admin) {
            return admin ? admin.name : "нет администратора"
        },
    }

}

</script>

<style scoped lang="scss">

@import "~@/theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.vote-card {
    background: $dom-light-gray;

    .vote-row.el-row {
        border-radius: 30px;
        background: $dom-light-gray;
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        display: flex;
        align-items: center;
        padding: 0;

        i {
            padding-left: 7px;
        }
    }


    //радио кнопки
    ::v-deep .el-radio__input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 2px;
        border: 2px solid $dom-gray;
    }

    ::v-deep .el-radio__input.is-checked {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 2px;
        border: 2px solid $dom-light-blue;
    }

    ::v-deep .el-radio__input.is-checked {
        .el-radio__inner {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $dom-green;
        }
    }

    ::v-deep .el-radio__inner {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    ::v-deep .vote-radio span {
        font-size: 16px;
    }

    .vote-radio {
        margin-left: 45px;
        padding: 14px 0 25px 0;
    }

    //радио кнопки


    .dropdown {
        ::v-deep .dropdown_button span {
            font-size: 14px;
        }

        ::v-deep .dropdown_button i {
            color: $dom-white
        }
    }

    ::v-deep .el-card__header {
        background: $dom-white;
        padding: 0 10px;

        .el-row__header {
            background: $dom-white;
            min-height: 55px;
            color: $dom-black;
            margin: 0;
            border-radius: 0;
            padding-left: 10px;
        }
    }

    .question {
        padding: 10px;

        ::v-deep .question__index {
            width: 30px;
            height: 30px;
            line-height: 30px;
            color: $dom-white;
            background: $dom-green;
            font-size: 18px;
            border-radius: 50%;
            margin: 0 !important;
            padding: 0 !important;
            text-align: center;
        }

        .question__title {
            background: $dom-light-gray;
            border-radius: 3px;
            font-size: 14px;
            min-height: 30px;

        }

        .question__title_icon {
            color: $dom-light-blue;
            padding-right: 10px;
            font-size: 16px;
        }

        .question__title_icon_finished {
            color: $dom-green;
            padding-right: 10px;
            font-size: 16px;
        }

        .question__title_icon_completed {
            color: $dom-red;
            padding-right: 10px;
            font-size: 16px;
        }

        .question__files {
            min-height: 20px;
            font-size: 14px;
            font-weight: 600;
            color: $dom-light-blue;
        }

        .question__files:last-child {
            margin-bottom: 0;
        }
    }

    .el-row {
        background: $dom-white;
        min-height: 55px;
        margin-bottom: 12px;
        border-radius: 4px;
        padding-left: 10px;

        ::v-deep i {
            //            color: $dom-light-blue
        }
    }

    .admin-button {
        i {
            margin-right: 10px;
        }

        width: 200px;
        border: 2px solid $dom-light-blue;
        border-radius: 30px;
    }

    .show-documents-button {
        i {
            margin-right: 10px;
        }

        border: 2px solid $dom-light-blue;
        border-radius: 30px;

        margin: 20px 16px;

        .text {
            color: $dom-black;
            margin-right: 30px;
        }

        .title {
            color: $dom-light-blue;
        }
    }


    .admin_approved {
        border-left: 8px solid $dom-yellow;
        margin-bottom: 10px;

        span.title {
            color: $dom-yellow;
            font-size: 18px;
            font-weight: 700;
            padding-left: 5px;
        }

        .admin_approved__icon {
            color: $dom-yellow;
        }
    }

    .counter {
        background: $dom-light-gray;
        border-radius: 4px;
        padding: 4px;
    }

    .counter__inner {
        font-family: 'Bebas Neue', cursive;
        word-break: normal;
        white-space: nowrap;
    }

    .counter__digit {
        font-size: 28px;
        margin-left: 5px;
    }

    .counter__letters {
        font-size: 12px;
        margin-left: 5px;
    }

}

</style>
