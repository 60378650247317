<template>
    <!--
    Диалог введения бумажного бюллетеня
    -->
    <el-dialog
        class="paper-dialog"
        :class="{dialog_full_display: !$isMobile(), dialog_mobile: $isMobile()}"
        :visible.sync="isActive"
        append-to-body
        center
        :close-on-click-modal="false"
        :show-close="false"
    >
        <template v-slot:title>
            <dom-title-closable value="Обработка бумажного бюллетеня" @close="onCancel"/>
        </template>

        <el-row align="middle" style="" type="flex" class="paper-dropdown dropdown">
            <dom-dropdown-search class="dropdown_button" label="Пользователь"
                                 left-icon="fas el-icon-fa-user"
                                 @input="selectUser"
                                 v-model="selectedUser" :items="users || []" value-key="user_id" text-key="user_name" use-key/>
        </el-row>

        <div v-if="bornDateIsUndefined" style="display: flex; align-items: center; margin-top: 10px;">
<!--            <span style="font-size: 20px; color: red; margin-right: 15px; top: 5px; position: relative;">*</span>-->
            <dom-datepicker v-model="selectedUserBornDate"
                            :disabled="!selectedUser"
                            placeholder="Дата рождения"
                            prefix-icon="fas el-icon-fa-calendar-alt"/>
        </div>

        <el-row class="" style="margin-bottom: 12px; margin-top: 10px;" :gutter="12">
            <el-col :span="24">
                <dom-checkbox v-model="useProxy" label="Голосование по доверенности"/>
            </el-col>

            <el-col :span="12" style="margin-top: 10px">
                <el-upload class="upload_button"
                           v-if="useProxy"
                           :auto-upload="false"
                           :show-file-list="false"
                           :limit="10485760"
                           :on-change="(file, fileList) => onAddProxyFile(file, fileList)"
                           action="">
                    <i class="fas el-icon-fa-file-upload ml-15"></i>
                    <span> Загрузить доверенность</span>
                </el-upload>

            </el-col>
        </el-row>

        <el-row style="margin-bottom: 12px; margin-top: 10px;" :gutter="12" v-if="useProxy">
            <el-col :xs="24" :sm="24" :md="12" style="margin-top: 5px">
                <dom-input v-model="proxyName" placeholder="Наименование доверенности" icon-left="fas el-icon-fa-info-circle"/>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" style="margin-top: 5px">
                <dom-datepicker v-model="proxyDate" placeholder="Дата доверенности" prefix-icon="fas el-icon-fa-calendar"/>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" style="margin-top: 5px">
                <dom-input v-model="proxyNumber" placeholder="Номер доверенности"/>
            </el-col>

        </el-row>

        <!--            Вопросы-->
        <el-row align="middle" v-for="(question, idx) in questions" :key="idx" class="paper-row">
            <el-row :gutter="14" type="flex" class="paper-row">
                <el-col :span="2" class="question__index">
                    {{ idx + 1 }}
                </el-col>
                <el-col :span="24" style="margin: 0">
                    <el-row type="flex" class="question__title" align="middle">
                        <i class="el-icon-question question__title_icon"></i>
                        <div class="question__header">{{ question.title }}</div>
                    </el-row>
                </el-col>
            </el-row>
            <!--                Блок результатов голосования-->
            <el-radio-group v-model="question.answer" class="vote-radio">
                <el-radio label="agree" style="margin-top: 5px">ЗА</el-radio>
                <el-radio label="disagree" style="margin-top: 5px">ПРОТИВ</el-radio>
                <el-radio label="abstain" style="margin-top: 5px">ВОЗДЕРЖАЛСЯ</el-radio>
            </el-radio-group>
        </el-row>
        <el-row>
            <el-col :span="24">
                <dom-upload @input="onAddFile">
                    <template v-if="votingDoc && votingDoc.name">
                        {{ votingDoc.name }}
                        {{ votingDoc.size | sizeFilter }}
                    </template>
                    <template v-else>
                        Загрузить решение
                    </template>
                </dom-upload>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="8">
                <dom-button style="margin-top: 10px"
                            @click="voteConfirmationDialog = true"
                            left-icon="el-icon-circle-check"
                            right-icon="el-icon-caret-right"
                            :disabled="!formIsCompleted"
                            label="Подтвердить обработку"/>
                <code-confirmation-dialog v-model="voteConfirmationDialog" :request-code="onGetVoteCode" :apply-code="onVoteCode"/>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
import moment from "moment";
import Toggleable from "@/views/mixins/Toggleable";
import DomButton from "@/views/elements/DomButton";
import DomTextarea from "@/views/elements/DomTextarea";
import {mapActions, mapGetters} from "vuex";
import DomDropdownSearch from "@/views/elements/DomDropdownSearch";
import DomCheckbox from "@/views/elements/DomCheckbox";
import DomInput from "@/views/elements/DomInput";
import DomDatepicker from "@/views/elements/DomDatepicker";
import api from "@/api";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import DomUpload from "@/views/elements/DomUpload";
import VueScreenSize from "vue-screen-size";

export default {
    name: "AddPaperBulletinDialogh",
    props: {},
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],
    components: {DomUpload, DomTitleClosable, CodeConfirmationDialog, DomDatepicker, DomInput, DomCheckbox, DomDropdownSearch, DomButton, DomTextarea},

    filters: {
        sizeFilter(val) {
            return `${parseInt((val / 1000000) * 100) / 100}мб`
        }
    },

    data() {
        return {
            question: '',
            algorithm: null,
            selectedUser: null,
            selectedUserBornDate: null,
            votingDoc: null,
            questions: [],

            voteConfirmationDialog: false,

            useProxy: false,
            proxyName: null,
            proxyDate: null,
            proxyNumber: null,
            proxyDoc: null,
        }
    },

    computed: {
        ...mapGetters("voting", {voting_id: 'voting_id', voteData: "voting", users: "users"}),

        allVotesMade() {
            return _.filter(this.questions, 'answer').length === this.questions.length
        },

        selectedUserObject() {
            return _.find(this.users, {user_id: this.selectedUser})

        },

        bornDateIsUndefined() {
            return this.selectedUserObject && (!this.selectedUserBornDate && this.selectedUserObject.jur_form === 'fl')
        },

        formIsCompleted() {
            const proxyCompleted = (!this.useProxy) || (this.proxyDoc && this.proxyName && this.proxyDate /*&& this.proxyNumber*/)
            const bornDateCompleted = this.selectedUserObject && (
                this.selectedUserObject.jur_form !== 'fl' || this.selectedUserBornDate
            )

            return this.selectedUser && this.allVotesMade && this.votingDoc && proxyCompleted
        }
    },

    mounted() {
        this.get_users(this.voting_id)

        this.questions = _(this.voteData.questions || [])
            .orderBy('ordr')
            .map(d => ({
                answer: null,
                questionId: d.questionId,
                title: d.title
            }))
            .value();
    },

    methods: {
        ...mapActions("voting", ["get_users", "get_statistics", 'load_docs']),

        selectUser(data) {
            this.users.forEach(el => {
                if (el.user_id == data) {
                    this.selectedUserBornDate = el.birth_date;
                }
            })
        },

        onCancel() {
            this.isActive = false;
            this.question = '';
        },

        onAddProxyFile(file) {
            this.proxyDoc = file;
        },

        onAddFile({file}) {
            this.votingDoc = file;
        },

        onGetVoteCode() {
            return api.voting.getVoteCode(this.voting_id)
        },

        onVoteCode(code) {
            const votes = _.map(this.questions, d => ({
                question_id: d.questionId,
                vote: d.answer,
            }));

            const proxyDate = this.proxyDate ? moment(this.proxyDate).toISOString() : null;
            const proxyDoc = this.proxyDoc ? this.proxyDoc.raw : null;
            const votingDoc = this.votingDoc ? this.votingDoc.raw : null;
            const user_birth_date = moment(this.selectedUserBornDate).toISOString();

            return api.voting.setOfflineVote(this.voting_id,
                votes,
                code,
                proxyDoc, this.proxyName, this.proxyNumber, proxyDate,
                votingDoc,
                this.selectedUser,
                user_birth_date)
                .then(() => {
                    this.voteConfirmationDialog = false;
                    this.$message.success("Спасибо, Ваш голос учтен!")
                    this.get_statistics(this.voting_id);
                    this.load_docs(this.voting_id);
                    this.isActive = false;
                })

        },

    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.dialog_full_display {
    max-height: 90vh;
    width: 80%;
    margin: 35px auto;
}

.dialog_mobile {
    width: 100%;
}

.paper-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        width: 100%;
        margin: 0 auto !important;
    }

    ::v-deep .el-dialog__body {
        background: $dom-light-gray;
    }

    //радио кнопки
    ::v-deep .el-radio__input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 2px;
        border: 2px solid $dom-light-blue;
    }

    ::v-deep .el-radio__input.is-checked {
        .el-radio__inner {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $dom-green;
        }
    }

    ::v-deep .el-radio__inner {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    ::v-deep .vote-radio span {
        font-size: 16px;
    }

    .vote-radio {
        margin-left: 45px;
        padding: 10px 0;
    }


    .paper-row {
        padding: 7px;
        background: $dom-white;
        min-height: 30px;
        margin-bottom: 12px;
        margin-top: 10px;
        border-radius: 4px;

        ::v-deep i {
            color: $dom-light-blue
        }


        ::v-deep .question__index {
            width: 30px;
            height: 30px;
            line-height: 30px;
            color: $dom-white;
            background: $dom-green;
            font-size: 18px;
            border-radius: 50%;
            margin: 0 !important;
            padding: 0 !important;
            text-align: center;
        }

        ::v-deep .question__title {
            background: $dom-light-gray;
            border-radius: 53px;
            font-size: 14px;
            min-height: 30px;

        }

        ::v-deep .question__title_icon {
            color: $dom-light-blue;
            padding-right: 10px;
            font-size: 24px;
        }
    }


}


</style>
